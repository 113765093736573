import React from 'react';
import Icons from 'components/atoms/Icons';
import { SubLinkItem, InternalLinksItem } from 'components/types/OSFHeaderTypes';

interface OSFNavMobileProps {
	groupedSubLinks: Array<{ heading: string; items: SubLinkItem[] }>;
	activeColumn: number | null;
	setActiveColumn: React.Dispatch<React.SetStateAction<number | null>>;
	activeSubColumn: number | null;
	setActiveSubColumn: React.Dispatch<React.SetStateAction<number | null>>;
	item: InternalLinksItem;
	setActiveSubNav: React.Dispatch<React.SetStateAction<string>>;
	cards: JSX.Element[];
}

const OSFNavMobile: React.FC<OSFNavMobileProps> = ({
	groupedSubLinks,
	activeColumn,
	setActiveColumn,
	activeSubColumn,
	setActiveSubColumn,
	item,
	setActiveSubNav,
	cards,
}) => {
	const backIcon = (
		<span className="icon">
			<Icons id="chevron-left-icon" />
		</span>
	);
	const forwardIcon = (
		<span className="icon">
			<Icons id="chevron-right-icon" />
		</span>
	);
	const filteredGroupedSubLinks = groupedSubLinks.filter(
		(group) => !(group.items[0].fields.image && group.items[0].fields.description)
	);
	const isDirectList = filteredGroupedSubLinks.every((group) => !group.heading);

	if (isDirectList) {
		return (
			<div className="sub-nav-column">
				<div className="sub-link-item">
					<div className="sub-link-heading" onClick={() => setActiveSubNav('')} tabIndex={0}>
						{backIcon} {item.fields.BaseLink.value.text || item.displayName || item.name}
					</div>
				</div>
				{cards.map((card, index) => (
					<div key={`card-${index}`} className="sub-link-item">
						{card}
					</div>
				))}
				{filteredGroupedSubLinks.map((group, index) => (
					<div className="sub-link-item" key={index}>
						<ul>
							<li>
								<a
									href={group.items[0].fields.generalLink?.value?.href}
									target={group.items[0].fields.generalLink?.value?.target}
								>
									{group.items[0].displayName}
								</a>
							</li>
						</ul>
					</div>
				))}
			</div>
		);
	}

	if (activeColumn === null) {
		return (
			<div className="sub-nav-column">
				<div key={`group-${item.displayName} || Date.now()`} className="sub-link-item">
					<div className="sub-link-heading" onClick={() => setActiveSubNav('')} tabIndex={0}>
						{backIcon} {item.fields.BaseLink.value.text || item.displayName || item.name}
					</div>
				</div>
				{cards.map((card, index) => (
					<div key={`card-${index}`} className="sub-link-item">
						{card}
					</div>
				))}
				{filteredGroupedSubLinks.map((group, index) => (
					<div
						key={index}
						className="sub-link-item nav-action"
						onClick={(e) => {
							e.stopPropagation();
							setActiveColumn(index);
							if (group.items.length === 1) {
								setActiveSubColumn(0);
							} else {
								setActiveSubColumn(null);
							}
						}}
						tabIndex={0}
					>
						{group.heading} {forwardIcon}
					</div>
				))}
			</div>
		);
	}

	if (activeSubColumn === null && filteredGroupedSubLinks[activeColumn]?.items.length > 1) {
		return (
			<div className="sub-nav-column">
				<div className="sub-link-heading" onClick={() => setActiveSubNav('')} tabIndex={0}>
					{backIcon} {item.fields.BaseLink.value.text || item.displayName || item.name}
				</div>
				{filteredGroupedSubLinks[activeColumn]?.items.map((subLink, index) => (
					<div
						key={index}
						className="sub-nav-column-heading"
						onClick={(e) => {
							e.stopPropagation();
							setActiveSubColumn(index);
						}}
						tabIndex={0}
					>
						{backIcon}
						{subLink.fields.heading?.value}
					</div>
				))}
			</div>
		);
	}

	const activeSubLinkGroup = filteredGroupedSubLinks[activeColumn];
	const activeSubLink = activeSubLinkGroup?.items[activeSubColumn || 0];

	return (
		<div className="sub-nav-column">
			<div className="sub-link-item">
				<div
					className="sub-link-heading"
					onClick={() => {
						setActiveSubColumn(null);
						setActiveColumn(null);
						if (groupedSubLinks.length === 1) {
							setActiveSubNav('');
						}
					}}
					tabIndex={0}
				>
					{backIcon} {activeSubLink?.fields?.heading?.value || '\u00A0'}
				</div>
			</div>
			{Array.isArray(activeSubLink?.fields?.lists) &&
				activeSubLink.fields.lists.map((list) => (
					<div className="sub-link-item" key={list.id}>
						<ul>
							<li>
								<a
									href={list.fields.generalLink?.value?.href}
									target={list.fields.generalLink?.value?.target}
								>
									{list.displayName}
								</a>
							</li>
						</ul>
					</div>
				))}
		</div>
	);
};

export default OSFNavMobile;
