import React, { useState, FormEvent } from 'react';
import axios from 'axios';
// import Constants from '../../../../../frontend/assets/js/utilities/Constants';

type PLDFormFields = {
	sectionName: string;
	requestorFName: string;
	requestorLName: string;
	requestorEmail: string;
	fName: string;
	lName: string;
	mName: string;
	suffix: string;
	addSpecialty: string;
	removeSpecialty: string;
	addLanguages: string;
	removeLanguages: string;
	addCertification: string;
	removeCertification: string;
	addTraining: string;
	removeTraining: string;
	locationDesc: string;
	addFocusArea: string;
	removeFocusArea: string;
	accepting: string;
	acceptingDept: string;
	background: string;
	biography: string;
	photo: string;
	comments: string;
};

const initialState: PLDFormFields = {
	sectionName: '',
	requestorFName: '',
	requestorLName: '',
	requestorEmail: '',
	fName: '',
	lName: '',
	mName: '',
	suffix: '',
	addSpecialty: '',
	removeSpecialty: '',
	addLanguages: '',
	removeLanguages: '',
	addCertification: '',
	removeCertification: '',
	addTraining: '',
	removeTraining: '',
	locationDesc: '',
	addFocusArea: '',
	removeFocusArea: '',
	accepting: '',
	acceptingDept: '',
	background: '',
	biography: '',
	photo: '',
	comments: '',
};

const resetState = {
	sectionName: '',
	fName: '',
	lName: '',
	mName: '',
	suffix: '',
	addSpecialty: '',
	removeSpecialty: '',
	addLanguages: '',
	removeLanguages: '',
	addCertification: '',
	removeCertification: '',
	addTraining: '',
	removeTraining: '',
	locationDesc: '',
	addFocusArea: '',
	removeFocusArea: '',
	accepting: '',
	acceptingDept: '',
	background: '',
	biography: '',
	photo: '',
	comments: '',
};

const successMsg =
	"We've received your request and someone will be in contact with you if there are any additional questions or follow up.";

const failedMsg = 'Sorry, something went wrong with the submission. Please try again.';

const PLDForm = () => {
	const [formFields, setFormFields] = useState({ ...initialState });
	const [currSlide, setCurrSlide] = useState(0);
	const [responseMsg, setResponseMsg] = useState('');
	const [validEmail, setValidEmail] = useState<boolean | null>(null);
	const userDetails = formFields.requestorFName !== '' && formFields.requestorLName !== '';
	const setMenuOpen = (el: Element) => {
		el.classList.toggle('open');
	};

	const handleChange = (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLSelectElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		document.querySelector('.user-error-msg')?.classList.remove('show');
		document.querySelectorAll('.error-msg')[currSlide - 2]?.classList.remove('show');
		setFormFields((prevFields) => ({
			...prevFields,
			[name]: value,
		}));
	};

	const clear = (input: HTMLInputElement) => {
		const { name } = input;
		setFormFields((prevFields) => ({
			...prevFields,
			[name]: '',
		}));
	};

	const validateEmail = () => {
		setValidEmail(formFields.requestorEmail.endsWith('@osfhealthcare.org'));
	};

	const validateForm = () => {
		return Object.values(formFields).filter((entry) => entry != '').length > 4;
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e?.preventDefault();
		if (validateForm()) {
			let formValues = Object.fromEntries(
				Object.entries(formFields).filter((entry) => entry[1] != '')
			);

			setResponseMsg('Thank you. Working on submitting...Please wait for confirmation.');
			axios
				.post(
					//CHANGE ENDPOINT HERE
					`http://localhost:3000/r2/kallie/form test`,
					{
						formFields: formValues,
					},
					{
						headers: {
							'Content-Type': 'application/json',
							//'Epic-Client-ID': Constants.api.epicClientID,
							Accept: 'application/json',
							//Authorization: `Basic ${Constants.api.epicAccessToken}`,
						},
					}
				)
				.then(function () {
					setResponseMsg(successMsg);
				})
				.catch(function () {
					setResponseMsg(failedMsg);
				});
			setCurrSlide(6);
			resetPartial();
		} else {
			document.querySelectorAll('.error-msg')[currSlide - 2]?.classList.add('show');
		}
	};

	const changeSection = (
		e: React.MouseEvent<HTMLButtonElement>,
		i: number,
		sectionName: string
	) => {
		e.preventDefault();
		e.stopPropagation();

		if (userDetails && validEmail) {
			setCurrSlide(i);
			setFormFields((formFields) => ({
				...formFields,
				sectionName: sectionName,
			}));
		} else if (formFields.requestorEmail.length == 0 || !userDetails) {
			document.querySelector('.user-error-msg')?.classList.add('show');
		}
	};

	const resetPartial = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e?.preventDefault();
		document.querySelectorAll('.error-msg')[currSlide - 2]?.classList.remove('show');
		setTimeout(() => {
			e && (currSlide == 1 ? setCurrSlide(0) : setCurrSlide(1));
			setFormFields((formFields) => ({
				...formFields,
				...resetState,
			}));
		}, 100);
	};

	return (
		<div className="overhauled">
			<div className="jss-inner-container pld-form outer-vertical-spacing">
				<h1 className="title">Provider Update Request Form</h1>
				<p className="osf-intro-copy">
					Use this form to request changes to a provider's record. Depending on what's being
					requested, different source systems are updated. Changes for provider demographics,
					locations, and phone numbers are sent to the Credentialing Verification Office. Online
					Scheduling and Accepting New Patient status is sent to IT. All other items are sent to the
					Marketing team.
				</p>
				<div className="form-wrapper">
					<form onSubmit={handleSubmit}>
						<div className={currSlide == 0 ? 'current slide' : 'slide'} id="0">
							<h4>Please provide your information in case follow up is needed:</h4>
							<fieldset>
								<label htmlFor="requestorFName">
									First Name<span className="required">*</span>
								</label>
								<input
									id="requestorFName"
									type="text"
									placeholder="Ex: John"
									name="requestorFName"
									value={formFields.requestorFName}
									onChange={handleChange}
									required
								></input>
								<label htmlFor="requestorLName">
									Last Name<span className="required">*</span>
								</label>
								<input
									id="requestorLName"
									type="text"
									placeholder="Ex: Doe"
									name="requestorLName"
									value={formFields.requestorLName}
									onChange={handleChange}
									required
								></input>
								<label htmlFor="requestorEmail">
									Email<span className="required">*</span>
								</label>
								<input
									id="requestorEmail"
									type="text"
									placeholder="Ex: john.doe@osfhealthcare.org"
									name="requestorEmail"
									value={formFields.requestorEmail}
									onBlur={validateEmail}
									onChange={handleChange}
									required
								></input>
								<em className={validEmail == false ? 'email-msg show' : 'email-msg'}>
									Please use your OSF email.
								</em>
							</fieldset>
							<p className="user-error-msg">Please fill out all of your information.</p>
							<button
								className="btn-tertiary next-btn"
								onClick={(e) => changeSection(e, 1, 'requestorSection')}
							>
								Next
							</button>
						</div>
						<div className={currSlide == 1 ? 'current slide' : 'slide'} id="1">
							<h4 className="buttons-title">Which type of information would you like to update?</h4>
							<div className="buttons">
								<div className="section-btn">
									<button
										key="2"
										className="btn-primary"
										onClick={(e) => changeSection(e, 2, 'personalSection')}
									>
										Personal Info
									</button>
									<em className="example-fields">
										Name, Specialties, Languages, Education, etc...{' '}
									</em>
								</div>
								<div className="section-btn">
									<button
										key="3"
										className="btn-primary"
										onClick={(e) => changeSection(e, 3, 'locationSection')}
									>
										Location Info
									</button>
									<em className="example-fields">Name, Address, Phone, etc... </em>
								</div>
								<div className="section-btn">
									<button
										key="4"
										className="btn-primary"
										onClick={(e) => changeSection(e, 4, 'marketableSection')}
									>
										Marketable Info
									</button>
									<em className="example-fields">
										Clinical Focus Area, Accepting New Pateients, Biography, etc...
									</em>
								</div>
							</div>
							<button className="btn-tertiary back-btn" onClick={(e) => resetPartial(e)}>
								Back
							</button>
						</div>
						<div key={2} className={currSlide == 2 ? 'current slide' : 'slide'}>
							<h3>Personal Information</h3>
							<p className="instructions">
								Instructions: For any field you want to update, enter the new information that
								should be in the profile.
							</p>
							<fieldset>
								<label htmlFor="fName">First Name</label>
								<input
									id="fName"
									type="text"
									placeholder="Ex: John"
									name="fName"
									value={formFields.fName}
									onChange={handleChange}
								></input>
								<label htmlFor="lName">Last Name</label>
								<input
									id="lName"
									type="text"
									placeholder="Ex: Doe"
									name="lName"
									value={formFields.lName}
									onChange={handleChange}
								></input>
								<label htmlFor="mName">Middle Name</label>
								<input
									id="mName"
									type="text"
									placeholder="Ex: Adam"
									name="mName"
									value={formFields.mName}
									onChange={handleChange}
								></input>
								<label htmlFor="suffix">Suffix</label>
								<input
									id="suffix"
									type="text"
									placeholder="Ex: MD"
									name="suffix"
									value={formFields.suffix}
									onChange={handleChange}
								></input>
								<label htmlFor="addSpecialty">Add Specialty</label>
								<input
									id="addSpecialty"
									type="text"
									placeholder="Ex: Family Medicine"
									name="addSpecialty"
									value={formFields.addSpecialty}
									onChange={handleChange}
								></input>
								<label htmlFor="removeSpecialty">Remove Specialty</label>
								<input
									id="removeSpecialty"
									type="text"
									placeholder="Ex: Family Medicine"
									name="removeSpecialty"
									value={formFields.removeSpecialty}
									onChange={handleChange}
								></input>
								<label htmlFor="addLanguages">Add Languages</label>
								<input
									id="addLanguages"
									type="text"
									placeholder="Ex: English"
									name="addLanguages"
									value={formFields.addLanguages}
									onChange={handleChange}
								></input>
								<label htmlFor="removeLanguages">Remove Languages</label>
								<input
									id="removeLanguages"
									type="text"
									placeholder="Ex: English"
									name="removeLanguages"
									value={formFields.removeLanguages}
									onChange={handleChange}
								></input>
								<label htmlFor="addCertification">Add Board Certification</label>
								<input
									id="addCertification"
									type="text"
									placeholder="Ex: Family Nurse Practitioner - American Academy of Nurse Practitioners, 2015"
									name="addCertification"
									value={formFields.addCertification}
									onChange={handleChange}
								></input>
								<label htmlFor="removeCertification">Remove Board Certification</label>
								<input
									id="removeCertification"
									type="text"
									placeholder="Ex: Family Nurse Practitioner - American Academy of Nurse Practitioners, 2015"
									name="removeCertification"
									value={formFields.removeCertification}
									onChange={handleChange}
								></input>
								<label htmlFor="addTraining">Add Training/Education</label>
								<input
									id="addTraining"
									type="text"
									placeholder="Ex: OSF Saint Francis Medical Center - Fellowship, 2015"
									name="addTraining"
									value={formFields.addTraining}
									onChange={handleChange}
								></input>
								<label htmlFor="removeTraining">Remove Training/Education</label>
								<input
									id="removeTraining"
									type="text"
									placeholder="Ex: OSF Saint Francis Medical Center - Fellowship, 2015"
									name="removeTraining"
									value={formFields.removeTraining}
									onChange={handleChange}
								></input>
								<label htmlFor="comments">
									Comments/Feedback -{' '}
									<em>Describe the issues, errors or information you would like to submit</em>
								</label>
								<textarea
									id="comments"
									name="comments"
									value={formFields.comments}
									onChange={handleChange}
								/>
							</fieldset>
							<p className="error-msg">*Please ensure at least one field is populated.</p>
							<div className="btns-wrapper">
								<button className="btn-tertiary back-btn" onClick={(e) => resetPartial(e)}>
									Back
								</button>
								<button type="submit" className="btn-primary submit-btn">
									Submit
								</button>
							</div>
						</div>
						<div key={3} className={currSlide == 3 ? 'current slide' : 'slide'}>
							<h3>Location Information</h3>
							<p className="instructions">
								Instructions: If you need to update any information related to practice locations,
								addresses, or phone numbers, please describe your request below. This information
								will be sent to the Credentialing Verification Office for final review and
								implementation.
							</p>
							<label htmlFor="name">
								Description<span className="required">*</span>
							</label>
							{/* UPDATE EX */}
							<textarea
								id="name"
								name="locationDesc"
								onChange={handleChange}
								value={formFields.locationDesc}
							></textarea>
							<label htmlFor="comments">
								Comments/Feedback -{' '}
								<em>Describe the issues, errors or information you would like to submit</em>
							</label>
							<textarea
								id="comments"
								name="comments"
								value={formFields.comments}
								onChange={handleChange}
							/>
							<p className="error-msg">*Please ensure at least one field is populated.</p>
							<div className="btns-wrapper">
								<button className="btn-tertiary back-btn" onClick={(e) => resetPartial(e)}>
									Back
								</button>
								<button type="submit" className="btn-primary submit-btn">
									Submit
								</button>
							</div>
						</div>
						<div key={4} className={currSlide == 4 ? 'current slide' : 'slide'}>
							<h3>Marketable Information</h3>
							<p className="instructions">
								Instructions: For any field you want to update, enter the new information that
								should be in the profile.
							</p>
							<label htmlFor="addFocusArea">Add Clinical Focus Area</label>
							<input
								id="addFocusArea"
								type="text"
								placeholder="Ex: Internal Medicine"
								name="addFocusArea"
								value={formFields.addFocusArea}
								onChange={handleChange}
							></input>
							<label htmlFor="removeFocusArea">Remove Clinical Focus Area</label>
							<input
								id="removeFocusArea"
								type="text"
								placeholder="Ex: Internal Medicine"
								name="removeFocusArea"
								value={formFields.removeFocusArea}
								onChange={handleChange}
							></input>
							<label htmlFor="accepting">Accepting New Patients</label>
							<select
								className="select-menu"
								id="accepting"
								onClick={(e) => setMenuOpen(e.currentTarget)}
								onKeyDown={(e) =>
									e.key == 'Enter' || e.key == ' ' ? setMenuOpen(e.currentTarget) : null
								}
								onBlur={(e) => setMenuOpen(e.target)}
								name="accepting"
								value={formFields.accepting}
								onChange={handleChange}
							>
								<option value="">Select an option</option>
								<option value="yes">Yes</option>
								<option value="no">No</option>
							</select>
							<div className={formFields.accepting != '' ? 'anp-dept show' : 'anp-dept'}>
								<label htmlFor="acceptingDept">
									To which Epic department(s) should this be applied?
								</label>
								<input
									id="acceptingDept"
									type="text"
									placeholder="Ex: 10000215 - OSF Medical Group - Pediatrics - Peoria Knoxville Avenue"
									name="acceptingDept"
									value={formFields.acceptingDept}
									onChange={handleChange}
									required={formFields.accepting != ''}
								></input>
							</div>
							<label htmlFor="background">Background</label>
							<input
								id="background"
								type="text"
								placeholder="Ex: John Doe was drawn to the medical field at an early age..."
								name="background"
								value={formFields.background}
								onChange={handleChange}
							></input>
							<label htmlFor="biography">Biography</label>
							<input
								id="biography"
								type="text"
								placeholder="Ex: I treat all patients with kindness and respect. It is important to me to provide personal education as well, so they feel informed and comfortable making decisions about their health care."
								name="biography"
								value={formFields.biography}
								onChange={handleChange}
							></input>
							<label htmlFor="photo">Photo Attachment</label>
							<input
								id="photo"
								type="file"
								name="photo"
								value={formFields.photo ? formFields.photo : ''}
								onChange={handleChange}
							></input>
							<button
								type="button"
								className={`${formFields.photo !== '' ? 'shown' : ''}  remove-btn btn-tertiary`}
								onClick={() => clear(document.getElementById('photo') as HTMLInputElement)}
							>
								Remove
							</button>
							<label htmlFor="comments">
								Comments/Feedback -{' '}
								<em>Describe the issues, errors or information you would like to submit</em>
							</label>
							<textarea
								id="comments"
								name="comments"
								value={formFields.comments}
								onChange={handleChange}
							/>
							<p className="error-msg">*Please ensure at least one field is populated.</p>
							<div className="btns-wrapper">
								<button className="btn-tertiary back-btn" onClick={(e) => resetPartial(e)}>
									Back
								</button>
								<button type="submit" className="btn-primary submit-btn">
									Submit
								</button>
							</div>
						</div>
					</form>
					<div key={6} className={currSlide == 6 ? 'current slide' : 'slide'}>
						<h2 className="success-msg">{responseMsg}</h2>
						<button className="btn-primary" onClick={() => setCurrSlide(0)}>
							Update other info
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PLDForm;
