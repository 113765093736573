import React, { useCallback, useEffect, useRef, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import GetViewportSize from '../../assets/utils/getViewportSize';
import { Image, Link } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from 'components/atoms/Icons';
import { classNames } from 'assets/utils/helper';
import {
	SubLinkItem,
	OSFHeaderProps,
	LinkItem,
	InternalLinksItem,
} from 'components/types/OSFHeaderTypes';
import OSFNavSubLinks from 'components/organisms/OSFNavSubLinks';

const OSFHeader = ({ fields }: OSFHeaderProps): JSX.Element => {
	const ref = useRef<HTMLInputElement>(null);
	const [mobileNavOpen, setMobileNavOpen] = useState(false);
	const [activeSubNav, setActiveSubNav] = useState('');

	const [activeColumn, setActiveColumn] = useState<number | null>(null);
	const [activeSubColumn, setActiveSubColumn] = useState<number | null>(null);

	if (!fields) return <div>no data source</div>;

	const groupSubLinks = (
		internalLinks: InternalLinksItem[] | undefined,
		activeSubNavId: string
	) => {
		const activeItem = internalLinks?.find((item) => item.id === activeSubNavId);

		if (!activeItem || !activeItem.fields.SubLink) return [];

		return activeItem.fields.SubLink.reduce(
			(acc: Array<{ heading: string; items: SubLinkItem[] }>, subLink: SubLinkItem) => {
				if (subLink.fields.heading?.value) {
					acc.push({
						heading: subLink.fields.heading.value,
						items: [subLink],
					});
				} else if (subLink.displayName && !subLink.fields.heading?.value) {
					subLink.fields.lists?.forEach((listItem) => {
						acc.push({
							heading: '',
							items: [listItem],
						});
					});
				} else if (acc.length > 0) {
					acc[acc.length - 1].items.push(subLink);
				}
				return acc;
			},
			[] as Array<{ heading: string; items: SubLinkItem[] }>
		);
	};

	const groupedSubLinks = groupSubLinks(fields?.internalLinks, activeSubNav);

	const openMobileNav = () => {
		const topOffset = `-${window.pageYOffset}px`;
		document.body.classList.add('body-fixed');
		document.body.style.top = topOffset;
		setMobileNavOpen(true);
	};

	const closeMobileNav = useCallback(() => {
		const mobileNavEl = ref?.current?.querySelector('.nav-menu');
		if (mobileNavEl) {
			mobileNavEl.classList.remove('mobile-nav-open');
			mobileNavEl.classList.add('closing');
		}

		const subNavMenus = ref?.current?.querySelectorAll('.sub-nav-menu');
		subNavMenus?.forEach((menu) => {
			(menu as HTMLElement).classList.remove('active');
		});

		setTimeout(() => {
			setMobileNavOpen(false);
			setActiveSubNav('');
			setActiveColumn(null);
			setActiveSubColumn(null);

			if (mobileNavEl) {
				mobileNavEl.classList.remove('closing');
			}
			document.body.classList.remove('body-fixed');
			document.body.style.removeProperty('top');
			window.scroll(0, parseInt(document.body.style.top || '0') * -1);
		}, 50);
	}, [ref, setMobileNavOpen, setActiveSubNav, setActiveColumn, setActiveSubColumn]);

	const openSubNav = (itemId: string) => {
		setActiveSubNav(itemId);

		const allSubNavMenus = ref.current?.querySelectorAll('.sub-nav-menu');
		allSubNavMenus?.forEach((menu) => {
			menu.classList.remove('active');
		});

		const internalLinkItem = fields.internalLinks?.find(
			(item: InternalLinksItem) => item.id === itemId
		);

		if (internalLinkItem && internalLinkItem.fields.SubLink) {
			const subLinks = internalLinkItem.fields.SubLink;
			const uniqueHeading = new Set(
				subLinks.map((link: SubLinkItem) => link.fields.heading?.value).filter(Boolean)
			);

			if (uniqueHeading.size === 1) {
				setActiveColumn(0);
				setActiveSubColumn(0);
			} else {
				setActiveColumn(null);
				setActiveSubColumn(null);
			}

			const subNavMenu = ref.current?.querySelector(`#sub-nav-${itemId}`);
			if (subNavMenu) {
				subNavMenu.classList.add('active');
			}
		} else {
			setActiveColumn(null);
			setActiveSubColumn(null);
		}
	};

	useEffect(() => {
		const handleResize = () => {
			const viewportSize = GetViewportSize();
			if (viewportSize === 'md' || viewportSize === 'lg' || viewportSize === 'xl') {
				closeMobileNav();
			}
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [closeMobileNav]);
	const featuredItems = fields.internalLinks?.filter((item) => item.fields.BaseSvg?.value);
	const listItems = mobileNavOpen
		? fields.internalLinks?.filter((item) => !item.fields.BaseSvg?.value)
		: fields.internalLinks;
	return (
		<header className="osf-header-nav" ref={ref}>
			{fields.links?.[0] && (
				<div className="back-button">
					<span className="icon icon-chevron-down">
						<Icons id="chevron-down" />
					</span>
					<Link field={fields.links[0].fields.BaseLink} className="back-button-link">
						{fields.links[0].fields.BaseLink.value.text}
					</Link>
				</div>
			)}
			<div className="nav-container">
				<div className="navbar">
					{fields.links?.[0] && (
						<div className="back-button">
							<span className="icon icon-chevron-down">
								<Icons id="chevron-down" />
							</span>
							<Link field={fields.links[0].fields.BaseLink} className="back-button-link">
								{fields.links[0].fields.BaseLink.value.text}
							</Link>
						</div>
					)}
					<div className="osf-logo nav-logo">
						<Link field={fields.homeUrl}>
							<Image field={fields.logo} alt="OSF HealthCare" />
						</Link>
						<button className="menu-open" onClick={openMobileNav}>
							<span className="icon icon-menu">
								<Icons id="menu-three" />
							</span>
						</button>
					</div>
				</div>
				<FocusTrap
					active={mobileNavOpen}
					focusTrapOptions={{
						escapeDeactivates: false,
					}}
				>
					<nav
						aria-hidden={
							GetViewportSize() !== 'md' &&
							GetViewportSize() !== 'lg' &&
							GetViewportSize() !== 'xl' &&
							!mobileNavOpen
						}
						aria-expanded={
							GetViewportSize() !== 'md' &&
							GetViewportSize() !== 'lg' &&
							GetViewportSize() !== 'xl' &&
							mobileNavOpen
						}
						className={`nav-menu ${mobileNavOpen ? 'mobile-nav-open' : ''}`}
					>
						<div className="nav-header">
							{fields.links?.[0] && mobileNavOpen && (
								<div className="back-button">
									<span className="icon icon-chevron-down">
										<Icons id="chevron-down" />
									</span>
									<Link field={fields.links[0].fields.BaseLink} className="back-button-link">
										{fields.links[0].fields.BaseLink.value.text}
									</Link>
								</div>
							)}
							<div className="osf-logo">
								<Link field={fields.homeUrl}>
									<Image field={fields.logo} alt="OSF HealthCare" />
								</Link>
							</div>
							<button className="menu-close" onClick={closeMobileNav}>
								<span className="">
									<Icons id="close-header" />
								</span>
							</button>
						</div>
						<div className="nav-content">
							{mobileNavOpen && (
								<div className="featured-items">
									{featuredItems?.map((item) => {
										return (
											<div
												key={item.id}
												className={classNames(
													'nav-action overhauled featured-item-wrapper',
													item.fields.SubLink?.length ? 'has-sub-nav' : '',
													activeSubNav === item.id && mobileNavOpen ? 'active' : ''
												)}
											>
												{!item.fields.SubLink?.length || item.fields?.BasePhoneNumber?.value ? (
													<a
														key={item.id}
														href={
															item.fields?.BasePhoneNumber?.value
																? `tel:${item.fields?.BasePhoneNumber?.value}`
																: item.fields?.BaseLink?.value?.href
														}
														target={item.fields?.BaseLink?.value?.target}
														className="featured-item"
													>
														<span className="featured-icon">
															<Icons id={item.fields.BaseSvg?.value} removeSize={true} />
														</span>
														<span className="featured-text">
															{item.fields?.BasePhoneNumber?.value ||
																item.fields.BaseLink.value.text ||
																item.displayName ||
																item.name}
														</span>
													</a>
												) : (
													<>
														<button
															onClick={() => openSubNav(item.id)}
															className={classNames('featured-item')}
														>
															<span className="featured-icon">
																<Icons id={item.fields.BaseSvg?.value} removeSize={true} />
															</span>
															<span className="featured-text">
																{item.fields.BaseLink.value.text || item.displayName || item.name}
															</span>
														</button>

														<div
															className={`sub-nav-menu ${activeSubNav === item.id ? 'active' : ''}`}
															id={`sub-nav-${item.id}`}
														>
															{item.fields?.SubLink && item.fields?.SubLink?.length > 0 && (
																<OSFNavSubLinks
																	subLinks={item.fields.SubLink}
																	maxColumnRows={parseInt(item.fields?.maxColumnRows?.value)}
																	columnNumber={parseInt(item.fields?.columnNumber?.value)}
																	activeColumn={activeColumn}
																	setActiveColumn={setActiveColumn}
																	activeSubColumn={activeSubColumn}
																	setActiveSubColumn={setActiveSubColumn}
																	groupedSubLinks={groupedSubLinks}
																	item={item}
																	setActiveSubNav={setActiveSubNav}
																/>
															)}
														</div>
													</>
												)}
											</div>
										);
									})}
								</div>
							)}
							<div className="main-nav">
								{listItems?.map((item) => {
									if (mobileNavOpen && item.fields.BaseSvg?.value) {
										return null;
									}
									return (
										<div
											key={item.id}
											className={classNames(
												'nav-action overhauled',
												item.fields.SubLink?.length ? 'has-sub-nav' : '',
												activeSubNav === item.id && mobileNavOpen ? 'active' : ''
											)}
										>
											{!item.fields.SubLink?.length ? (
												<a
													href={
														item.fields?.BasePhoneNumber?.value
															? `tel:${item.fields?.BasePhoneNumber?.value}`
															: item.fields?.BaseLink?.value?.href
													}
													target={item.fields?.BaseLink?.value?.target}
													className={classNames(
														Boolean(item.fields.BackgroundColor?.fields.Phrase.value)
															? 'btn-primary'
															: 'nav-action-btn'
													)}
													style={
														item.fields.BackgroundColor
															? {
																	backgroundColor: item.fields.BackgroundColor?.fields.Phrase.value,
															  }
															: {}
													}
												>
													{item.fields?.BasePhoneNumber?.value ||
														item.fields.BaseLink.value.text ||
														item.displayName ||
														item.name}
												</a>
											) : (
												<>
													{item.fields?.BasePhoneNumber?.value ? (
														<a
															className="nav-action-btn"
															href={`tel:${item.fields?.BasePhoneNumber?.value}`}
														>
															{item.fields?.BasePhoneNumber?.value}
														</a>
													) : (
														<button
															onClick={() => openSubNav(item.id)}
															className={classNames('nav-action-btn')}
														>
															{item.fields.BaseLink.value.text || item.displayName || item.name}
															{mobileNavOpen && (
																<span className="chevron-right-icon">
																	<Icons id="chevron-right-icon" />
																</span>
															)}
														</button>
													)}
													<FocusTrap
														active={activeSubNav === item.id}
														focusTrapOptions={{
															escapeDeactivates: false,
															allowOutsideClick: true,
															clickOutsideDeactivates: false,
															fallbackFocus: () => ref.current ?? document.body,
														}}
													>
														<div
															className={`sub-nav-menu ${activeSubNav === item.id ? 'active' : ''}`}
															id={`sub-nav-${item.id}`}
														>
															{item.fields?.SubLink && item.fields?.SubLink?.length > 0 && (
																<OSFNavSubLinks
																	subLinks={item.fields.SubLink}
																	maxColumnRows={parseInt(item.fields?.maxColumnRows?.value)}
																	columnNumber={parseInt(item.fields?.columnNumber?.value)}
																	activeColumn={activeColumn}
																	setActiveColumn={setActiveColumn}
																	activeSubColumn={activeSubColumn}
																	setActiveSubColumn={setActiveSubColumn}
																	groupedSubLinks={groupedSubLinks}
																	item={item}
																	setActiveSubNav={setActiveSubNav}
																/>
															)}
														</div>
													</FocusTrap>
												</>
											)}
										</div>
									);
								})}
							</div>
							<div className="nav-utility">
								<div className="utility-links">
									{fields.links?.slice(1).map((link: LinkItem) => (
										<a
											key={link.id}
											href={
												link.fields?.BasePhoneNumber?.value
													? `tel:${link.fields?.BasePhoneNumber?.value}`
													: link.fields?.BaseLink?.value?.href
											}
											target={link.fields?.BaseLink?.value?.target}
											className="utility-link"
										>
											{link.fields?.svg?.value && (
												<span className="icon icon-location">
													<Icons id={link.fields?.svg?.value} />
												</span>
											)}
											{link.fields?.BasePhoneNumber?.value || link.displayName}
										</a>
									))}
								</div>
							</div>
						</div>
					</nav>
				</FocusTrap>
			</div>
		</header>
	);
};

export default OSFHeader;
