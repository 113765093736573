import { gql, GraphQLClient } from 'graphql-request';
import { Redirect } from 'next';
import config from 'temp/config';

export default async function sitecoreRedirect(sourceUrl: string): Promise<Redirect | undefined> {
	// Get the required values from configuration
	const endpoint = config.graphQLEndpoint;
	const apiKey: string = config.sitecoreApiKey ?? '{83B432B0-3E2B-41D5-BA34-46569D0C743D}';
	const pathId: string = process.env.SC_REDIRECT_PATH_ID ?? '14DA60FDAFE94F93B7971AC11BB1F6E2';
	const templateId: string =
		process.env.SC_REDIRECT_TEMPLATE_ID ?? '5D86AAE9A50C40F3937775FD617ED20D';

	// Return undefined if not all required configuration values are present
	if (!endpoint || !apiKey || !pathId || !templateId) {
		console.log('invalid config');
		return undefined;
	}

	const graphQLClient = new GraphQLClient(endpoint);
	graphQLClient.setHeader('sc_apikey', apiKey);
	graphQLClient.setHeader('Access-Control-Allow-Methods', 'OPTIONS, GET, POST');

	// Declaration of the GraphQL query that will search a redirect by source URL
	const query = gql`
		query SearchRedirect($pathId: String!, $templateId: String!, $sourceUrl: String!) {
			search(
				where: {
					AND: [
						{ name: "_path", value: $pathId, operator: CONTAINS }
						{ name: "_template", value: $templateId, operator: CONTAINS }
						{ name: "SourceUrl", value: $sourceUrl }
					]
				}
				first: 1
			) {
				total
				pageInfo {
					endCursor
					hasNext
				}
				results {
					sourceUrl: field(name: "SourceUrl") {
						jsonValue
					}
					redirectItem: field(name: "RedirectItem") {
						jsonValue
					}
					redirectUrl: field(name: "RedirectUrl") {
						value
					}
					isPermanent: field(name: "IsPermanent") {
						jsonValue
					}
				}
			}
		}
	`;
	// GraphQL query is executed with the required parameters
	const result = await graphQLClient.request(query, {
		sourceUrl: sourceUrl,
		pathId: pathId,
		templateId: templateId,
	});
	let redirectUrl = '';
	// If no redirect found, return empty
	if (result.search.total !== 0) {
		// Retrieve the redirect URL
		if (result.search.results[0]?.redirectItem?.jsonValue?.url) {
			redirectUrl = result.search.results[0]?.redirectItem?.jsonValue?.url;
		} else if (result.search.results[0]?.redirectUrl?.value) {
			redirectUrl = result.search.results[0]?.redirectUrl?.value;
		}
	}

	// Set the isPermanent flag depending on the isPermanent checkbox in Sitecore redirect item
	let isPermanent = true;
	if (result.search.results[0]?.isPermanent) {
		isPermanent = result.search.results[0]?.isPermanent?.jsonValue.value;
	}

	// Return the Redirect object
	return {
		permanent: isPermanent,
		destination: redirectUrl,
	};
}
