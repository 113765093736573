import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { GetStaticPaths, GetStaticProps } from 'next';
import NotFound from 'src/NotFound';
import Layout from 'src/Layout';
import {
	SitecoreContext,
	ComponentPropsContext,
	handleExperienceEditorFastRefresh,
	RouteData,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideSitecoreContextValue } from 'lib/component-props';
import { SitecorePageProps } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import { componentFactory } from 'temp/componentFactory';
import { sitemapFetcher } from 'lib/sitemap-fetcher';
import sitecoreRedirect from 'lib/sc-redirect';

const SitecorePage = ({ notFound, layoutData, componentProps }: SitecorePageProps): JSX.Element => {
	const router = useRouter();
	const isRedirecting = useRef(false);

	useEffect(() => {
		// Handle Experience Editor Fast Refresh
		handleExperienceEditorFastRefresh();

		// Handle redirects
		const handleRedirect = async () => {
			if (isRedirecting.current) {
				return;
			}

			const resolvedPath = Array.isArray(router.query.path)
				? `/${router.query.path.join('/')}`
				: `/${router.query.path || ''}`;

			const redirect = await sitecoreRedirect(resolvedPath);

			if (redirect) {
				isRedirecting.current = true;
				router.push(redirect.destination);
				return;
			}
		};

		// Check for redirects even if the page is not found
		if (notFound || !layoutData?.sitecore?.route) {
			handleRedirect();
		} else {
			// Still check redirects for existing pages (scenarios 1 and 2)
			handleRedirect();
		}
	}, [router.query.path, notFound, layoutData]);

	if ((notFound || !layoutData?.sitecore?.route) && !isRedirecting.current) {
		return <NotFound />;
	}

	const context: StyleguideSitecoreContextValue = {
		route: layoutData?.sitecore?.route || ({} as RouteData),
		itemId: layoutData?.sitecore?.route?.itemId || undefined,
		...(layoutData?.sitecore?.context || {}),
	};

	return (
		<ComponentPropsContext value={componentProps}>
			<SitecoreContext<StyleguideSitecoreContextValue>
				componentFactory={componentFactory}
				context={context}
			>
				<Layout context={context} />
			</SitecoreContext>
		</ComponentPropsContext>
	);
};

// This function gets called at build and export time to determine pages for SSG
export const getStaticPaths: GetStaticPaths = async (context) => {
	// Fallback, along with revalidate in getStaticProps (below),
	// enables Incremental Static Regeneration. This allows us to
	// leave certain (or all) paths empty if desired and static pages
	// will be generated on request (development mode in this example).
	// Alternatively, the entire sitemap could be pre-rendered
	// ahead of time (non-development mode in this example).
	// See https://nextjs.org/docs/basic-features/data-fetching#incremental-static-regeneration

	if (process.env.NODE_ENV !== 'development') {
		// Note: Next.js runs export in production mode
		const paths = await sitemapFetcher.fetch(context);

		return {
			paths,
			fallback: process.env.EXPORT_MODE ? false : 'blocking',
		};
	}

	return {
		paths: [],
		fallback: 'blocking',
	};
};

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation (or fallback) is enabled and a new request comes in.
export const getStaticProps: GetStaticProps<SitecorePageProps> = async (context) => {
	try {
		const props = await sitecorePagePropsFactory.create(context);
		const defaultLocale = context.defaultLocale || 'en';

		if (props.notFound) {
			return {
				props: {
					notFound: true,
					layoutData: null,
					componentProps: {},
					locale: props.locale || defaultLocale,
					dictionary: props.dictionary || {},
				},
				revalidate: 5,
			};
		}

		return {
			props,
			revalidate: 5,
		};
	} catch (error) {
		console.error('Error in getStaticProps:', error);
		return {
			props: {
				notFound: true,
				layoutData: null,
				componentProps: {},
				locale: context.defaultLocale || 'en',
				dictionary: {},
			},
			revalidate: 5,
		};
	}
};

export default SitecorePage;
